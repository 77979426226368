<template>
  <div class="opt">
    <optHero/>
    <optOffers/>
    <optPayments/>
    <optBonuses/>
    <optPartner/>
    <optAddresses/>
    <partnersReviews/>
  </div>
</template>

<script>
  export default {
    name: 'Opt',
    components: {
      OptHero: () => import('@/components/pages/magnum-opt/OptHero.vue'),
      OptOffers: () => import('@/components/pages/magnum-opt/OptOffers.vue'),
      OptPayments: () => import('@/components/pages/magnum-opt/OptPayments.vue'),
      OptBonuses: () => import('@/components/pages/magnum-opt/OptBonuses.vue'),
      OptPartner: () => import('@/components/pages/magnum-opt/OptPartner.vue'),
      OptAddresses: () => import('@/components/pages/magnum-opt/OptAddresses.vue'),
      PartnersReviews: () => import('@/components/common/PartnersReviews.vue'),
    }
  }
</script>